import React, { useState } from 'react';
import './Request.css';
import Get from '../Image/Get in touch.gif';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';

const Request = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gmail, setGmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const data ={
      Name:name,
      LastName:lastName,
      Gamil:gmail,
      Number:number,
      Message:message
    }
    axios.post(`https://sheet.best/api/sheets/d5d5f700-107d-458f-a528-f4d816ff9f8a`,data).then(response=>{
      setName("");
      setLastName("");
      setGmail("");
      setNumber("");
      setMessage("");
    })
  };

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1200,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }, []);

  return (
    <section className='py-4' id='demo'>
      <Container data-aos='fade-up'>
        <Row>
          <Col md={12} className='text-center py-2'>
            <h1 className='h1 text-capitalize my-1' style={{ color: '#289E8A' }}>
              Book your Free Demo
            </h1>
          </Col>
        </Row>
        <Row className='justify-content-between align-items-center flex-md-row flex-column-reverse'>
          <Col md={6} className='d-flex justify-content-center align-items-center'>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className='p-3'>
                  <input
                    required
                    placeholder='first name'
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Col>
                <Col md={6} className='p-3'>
                  <input
                    required
                    placeholder='last name'
                    type='text'
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </Col>
                <Col md={6} className='p-3'>
                  <input
                    required
                    placeholder='E-mail'
                    type='email'
                    onChange={(e) => setGmail(e.target.value)}
                    value={gmail}
                  />
                </Col>
                <Col md={6} className='p-3'>
                  <input
                    required
                    placeholder='phone'
                    type='number'
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                  />
                </Col>
                <Col md={12}>
                  <textarea
                    required
                    placeholder='write your message'
                    cols='30'
                    rows='1'
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                </Col>
                <Col className='text-end mt-4'>
                  <input className='btn md-mx-auto px-4 py-md-3 py-2 btn-request' type='submit' value='Send Message' />
                </Col>
              </Row>
            </form>
          </Col>

          <Col md={6} className='d-flex justify-content-center align-items-center'>
            <img style={{ width: '70%' }} className='img-fluid' src={Get} alt='img' />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Request;
