import "./Cards.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from 'react-bootstrap/esm/Col';
import { BiCheck } from "react-icons/bi";
import { Link } from "react-scroll";
import Data from "./DataCards";


function Cards() {
  return (
    <div className="card-container py-5" id="services">
      <Container>
        <Row>
          <Col md="12" className="text-center py-5">
           <h3>Detailed descriptions of the services  you offer Suitable Online <br/> Test Platform for Smooth Exams</h3>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {
            Data.map((item)=>{
              return(
                <Col data-aos="fade-up" md='6' lg='6' xl="3" className="my-3" >
                <div className="card card-bg " style={{ borderRadius: '5px 25px 5px 50px' }}>
                  <div className="img-box d-flex justify-content-center mx-auto">
                    <img className="card-img-top cardImg " src={item.src} alt="Card" />
                  </div>
                  <div className="card-body d-flex  flex-column mx-auto py-4" >
                    <h6 className="card-title text-center">{item.title}</h6>
                    <div className="d-flex  py-1 gap-1  "><span><BiCheck className="icon" color="rgb(40,158,138)" /></span><p className="card-text ">{item.point1}</p></div>
                    <div className="d-flex py-1 gap-1  "><span><BiCheck className="icon" color="rgb(40,158,138)" /></span><p className="card-text">{item.point2}</p></div>
                    <div className="d-flex py-1 gap-1 "><span><BiCheck className="icon" color="rgb(40,158,138)" /></span><p className="card-text">{item.point3}</p></div>
                    <div className="d-flex py-1 gap-1"><span><BiCheck className="icon" color="rgb(40,158,138)" /></span><p className="card-text">{item.point4}</p></div>
                    {/* <Link to="https://app.aakalan.org/" target="_blank" className="btn card-btn mt-5 ">Explore Now</Link> */}
                    <Link 
  to="#" 
  className="btn card-btn mt-5"
  onClick={(e) => {
    e.preventDefault(); // Prevents the default behavior of the link
    window.open('https://app.aakalan.org/', '_blank', 'noopener noreferrer');
  }}
>
  Explore Now
</Link>

                  </div>
                </div>
              </Col>

              )
            })
          }
       
        </Row>
      </Container>
    </div>

  );
}

export default Cards;