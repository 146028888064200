import Anti from "../Image/smallCard/Anti-Cheat_Measures.png"
import Impeccable from "../Image/smallCard/Impeccable_Data_Security.png"
import Insightful from "../Image/smallCard/Insightful_Performance_Analytics.png"
import Intelligent from "../Image/smallCard/Intelligent_Question_Phasing.png"
import Precision from "../Image/smallCard/Precision_Assessment_Creation.png"
import Seamless from "../Image/smallCard/Seamless_Assessment_Reminders.png"
import Streamlined from "../Image/smallCard/Streamlined_Question_Bank_Upload.png"
import Unparalleled from "../Image/smallCard/Unparalleled_Online_Offline_Capability.png"
const FeaturesData=[
    {
        id:"1",
        src:Unparalleled,
        title:"Unparalleled Online/Offline Capability",
        text:"Aakalan's efficiency extends to remote areas with seamless transitions between online and offline modes while retaining all features"
     },
  
    {
        id:"2",
        src:Anti,
        title:"Vigilant Anti-Cheat Measures",
        text:"Our platform incorporates real-time proctoring to safeguard the integrity of assessments. Live video feeds and random photo capturing at regular intervals ensure a secure testing environment"
     },
     {
        id:"3",
        src:Insightful,
        title:"Insightful Performance Analytics",
        text:"Easily decipher performance data through graphical views. Filter results by Assessor, Training Partner, Job Role, State, and Date, gaining comprehensive insights at a glance"
     },
     
     {
        id:"4",
        src:Impeccable,
        title:"Precise Assessment Tracking",
        text:"Aakalan meticulously tracks assessments through Geo Location and Timestamps. Whether online or offline, every Assessor login and logout is recorded and communicated to SSC/AB."
     },
     {
        id:"5",
        src:Seamless,
        title:"Seamless Assessment Reminders",
        text:"Ensure a smooth assessment process with automated reminder emails sent to AB, Assessor, and SSC 24 hours prior to the assessment"
     },
     {
        id:"6",
        src:Intelligent,
        title:"Intelligent Question Phasing",
        text:"Aakalan categorizes questions effortlessly as easy or tough, analyzing wrong and right attempts within a specific timeframe. A downloadable usage report helps you monitor question effectiveness"
     },
     {
        id:"7",
        src:Streamlined,
        title:"Streamlined Question Bank Upload",
        text:"Simplify question bank management by uploading it in a user-friendly Excel format, including NOS and PC-wise mapping, difficulty levels, and marks."
     },
  
     {
        id:"8",
        src:Precision,
        title:"Precision Assessment Creation",
        text:"Aakalan automates assessment creation aligned with standards, saving time and ensuring consistency by generating multiple question papers from a question bank"
     },
]

export default FeaturesData;