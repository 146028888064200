import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Online from "../Image/Remote team.gif"
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';

const Demo = () => {
  useEffect(()=>{
    Aos.init({ offset: 200,
      duration: 1500,
      easing: 'ease-in-sine',
      delay: 100,
      
    });

  },[]);
  return (
    <section>
      <Container data-aos="fade-up"  >
        <Row className='justify-content-center align-items-center'> 
        <Col md="6" className='d-flex justify-content-center align-items-center'>
        <img style={{width:'70%'}} className='img-fluid ' src={ Online} alt='img'/>
      </Col>
            <Col  md="6" className='d-flex justyfy-content-center  flex-column'>
                <h3 className='pt-6 fs-2'>Listen to audio of candidate during exams</h3>
                <p className='fs-6 mt-3'>The system can help listen to the live audio of remote users attempting the online exam.</p>
                <p className='fs-6'>We can record the entire audio of the exam session for audit purposes. It is helpful to conduct viva exams as well.</p>
            
            </Col>
            
        </Row>
      </Container>

    </section>
  )
}

export default Demo