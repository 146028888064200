import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Button from 'react-bootstrap/Button';
import "./Product.css";
import { AiOutlineArrowRight } from "react-icons/ai";


const Product = () => {
    return (
        <section style={{ backgroundColor: '#F7FAFF' }}  id='product' className='py-5'>
            <Container className='faturesCard'>
                <Row className='py-5 '>
                    <Col md="12" className='text-center'>
                        <h2 className='fs-1 '>Evaluation System Fostering Insightful Assessment</h2>
                        <p className='fs-6 py-2'>Aakalan's Evaluation System goes beyond traditional assessment methods, offering a holistic approach to <br /> evaluating skills and ensuring the utmost authenticity and excellence</p>
                    </Col>
                </Row>
                <Row className='justify-content-center gap-4 gap-lg-0 '>

                    <Col data-aos="fade-up" xl="4" lg="6" className='mt-2 lg-mt-0'>
                        <div className=' d-flex justify-content-center gap-2'>
                            <div><AiOutlineArrowRight className='fs-6 ' /></div>
                            <div>
                                <h6 className='fs-4 fatures-text'>Result Analysis Report</h6>
                                <p className='fs-6 text-p'>
                                    Consolidated Report Get a comprehensive view of overall performance Job Role-wise Report Drill down into performance by specific job rolesState-wise Report Assess performance on a regional level Training Partner-wise Report: Evaluate performance across different training partners Combined Batch Analysis: Gain insights from a combination of batch data</p>

                                <Button className='buttonF mt-2' variant="outline-primary">Learn more</Button>
                            </div>
                        </div>
                    </Col>

                    <Col xl="4" lg="6" data-aos="fade-up" className='mt-5 mt-lg-0' >
                        <div className=' d-flex justify-content-center gap-2' >
                            <div><AiOutlineArrowRight className='fs-6 ' /></div>
                            <div>
                                <h6 className='fs-4 fatures-text'>Detailed Assessor Report</h6>

                                <p className='fs-6'> Assessor Report Batch-wise: Verify the authenticity of assessors through batch-wise reports
                                    Geo Location Tagging Track assessments with precision using geolocation data
                                    Login and Logout Timestamps Ensure the accuracy of assessment sessions with timestamp records
                                    Result Analysis Training Partner-wise Analyze training partner effectiveness
                                    Combined Batch Analysis: Comprehensive insights from combined batch data
                                </p>
                                <Button className='buttonF' variant="outline-primary">Learn more</Button>
                            </div>
                        </div>
                    </Col>
                    <Col xl="4" lg="6" data-aos="fade-up" className='mt-5 mt-lg-0'>
                        <div className=' d-flex justify-content-center gap-2' >
                            <div><AiOutlineArrowRight className='fs-6 ' /></div>
                            <div>
                                <h6 className='fs-4 fatures-text'>Candidate Log Report</h6>
                                <p className='fs-6'>Detailed Candidate Logs Access comprehensive logs for individual candidates
                                    Multimedia Records View photos and videos captured during assessments
                                    Timestamp Records Ensure the accuracy of assessment duration
                                    Geolocation Tracking Track candidates' assessment locations with precision
                                    author instructions customize consents  set up access rules limit attempts and more</p>
                                <Button className='buttonF md-mt-3' variant="outline-primary">Learn more</Button>
                            </div>
                        </div>
                    </Col>
                  
             
                    <Col data-aos="fade-up" xl="4" lg="6" className='mt-5'>
                        <div className=' d-flex justify-content-center gap-2'>
                            <div><AiOutlineArrowRight className='fs-6 ' /></div>
                            <div>
                                <h6 className='fs-4 fatures-text'>Question Bank Report</h6>
                                <p className='fs-6'>Monitor question usage Track how often questions are used in assessments
                                    Repetition Analysis Identify question repetition patterns
                                    Correct and Incorrect Attempts Ratio Understand how candidates are performing on each question
                                </p>
                                <Button className='buttonF ' variant="outline-primary">Learn more</Button>
                            </div>
                        </div>
                    </Col>
                    <Col data-aos="fade-up" xl="4" lg="6" className='mt-5'>
                        <div className=' d-flex justify-content-center gap-2 mt-2 lg-mt-0' >
                            <div><AiOutlineArrowRight className='fs-6 ' /></div>
                            <div>
                                <h6 className='fs-4 fatures-text'>Student Feedback Report</h6>
                                <p className='fs-6'>Insightful Feedback: Gain valuable insights into students' understanding of the skill ecosystem
                                    Enhance Training Use feedback to tailor training programs for improved outcomes
                                </p>

                                <Button className='buttonF mt-md-2' variant="outline-primary">Learn more</Button>
                            </div>
                        </div>
                    </Col>
                    <Col  data-aos="fade-up"xl="4" lg="6" className='mt-5' >
                        <div className=' d-flex justify-content-center gap-2' >
                            <div><AiOutlineArrowRight className='fs-6 ' /></div>
                            <div>
                                <h6 className='fs-4 fatures-text'>Test delivery options</h6>
                                <p className='fs-6'>Configure how your tests are delivered and secured, author instructions customize consents set up access rules limit attempts and more</p>
                                <Button className='buttonF mt-md-4' variant="outline-primary">Learn more</Button>
                            </div>
                        </div>
                    </Col>
                   
                </Row>

            </Container>
        </section>
    )
}

export default Product