import  Card1 from "../Image/card-logo/1.png";
import Card2 from "../Image/card-logo/2.png"
import Card3 from "../Image/card-logo/3.png"
import Card4 from "../Image/card-logo/4.png"
const Data =[
    {
        id:"1",
        src:Card1,
        title:"Online Examination Solution",
        point1:"Scalable and feature rich system",
        point2:"Integrated with in-built/your LMS",
        point3:"Best fit for entrance/weekly/semester Exams",
        point4:"Service acrossed School/Coaching/University"

    },
    {
        id:"2",
        src:Card2,
        title:"AI-Based Cheating Detection",
        point1:"Text analysis for similarity detectio",
        point2:"Keystroke dynamics for typing patterns",
        point3:"Behavioral analysis for suspicious activities",
        point4:"Image recognition for visual components"

    },
    {
        id:"3",
        src:Card3,
        title:"Proctoring Services",
        point1:"Monitor online exams for integrity",
        point2:"Verify test-taker identity securely",
        point3:"Use live or automated methods for supervision",
        point4:"Online proctored exams are easy to set up and customize"

    },
    {
        id:"4",
        src:Card4,
        title:"Skill India Initiative Assessments",
        point1:"Skill Acquisition",
        point2:"Training",
        point3:"Provide feedback for program improvement",
        point4:"Provides career guidance and counseling services"

    },
]
export default Data;