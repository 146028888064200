
import React, { useState, useEffect } from 'react';
import "./Navigation.css";
import Logo from "../Image/demo/logo.png"
import {Link} from "react-scroll"
import Hamburger from 'hamburger-react'


function Navigation() {
  const [navSize, setnavSize] = useState("6rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#A5DED4") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("6rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
 <section id='home'>
  <nav className="navbar  navbar-expand-lg fixed-top mb-5" style={{ backgroundColor: navColor,
        height: navSize,
        transition: "all 1s"}}>
    <div className="container mob-bg">
    <div className='d-flex align-items-center justify-content-center gap-2'>
    <Link to="home" spy={true} smooth={true} offset={-100} duration={500} className="navbar-brand d-flex align-items-center justify-content-center gap-2" href="#"> <img style={{ width: "12rem" }} src={Logo} alt='logo' />
    {/* |<h3 className='fs-5 mt-3 ' style={{ color: '#289E8A' }}>Aakalan.org</h3> */}
    </Link>
    </div>
    <button  className="navbar-toggler p-0 " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <Hamburger size={28} color='Rgb(7,94,110)' />

    </button>
    <div className="collapse navbar-collapse nav-bg " id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
        <li className="nav-item">
          <Link to="home" spy={true} smooth={true} offset={-100} duration={500} className="nav-link active text-link mx-2" aria-current="page" href="#">Home</Link>
        </li>
       
        <li className="nav-item">
          <Link to="services" spy={true} smooth={true} offset={50} duration={500} className="nav-link text-link mx-2" aria-current="page" href="#">Services</Link>
        </li>
        <li className="nav-item">
          <Link to="features" spy={true} smooth={true} offset={-80} duration={500} className="nav-link text-link mx-2" aria-current="page" href="#">Features
</Link>
        </li>
        <li className="nav-item">
          <Link to="product" spy={true} smooth={true} offset={-70} duration={500} className="nav-link text-link mx-2" aria-current="page" href="#">Product</Link>
        </li>
        <li className="nav-item">
        <a class="nav-link text-link mx-2" aria-current="page" href="https://www.demorgia.com/#about"target='blank'>About Us</a>
        </li>

        <div><Link to="demo" spy={true} smooth={true} offset={-40} duration={500} className='btn btn-demo mt-1 py-2 ' type="submit">Request Demo</Link></div>
       
      </ul>
    </div>
  </div>
</nav>

 </section>
  );
}

export default Navigation;