import React from 'react';
import Cimg from "../Image/small-logo/small2.png";
import Cmmi from "../Image/small-logo/small4.png";
import elets from "../Image/small-logo/small5.png";
import logo6 from  "../Image/small-logo/small6.png";
import logo7 from  "../Image/small-logo/small7.png";
import logo8 from  "../Image/small-logo/small8.png";
import logo9 from  "../Image/small-logo/small9.png";

import "./Certification.css"


const Certification = () => {
  return (
   <div className=' logo'>
     <div className='logo-slide'>
     <img src={Cimg} alt=""/>
     <img id='c-img' src={Cmmi} alt=""/>
     <img src={elets} alt=""/>
     <img src={logo6} alt=""/>
     <img src={logo7} alt=""/>
     <img src={logo8} alt=""/>
     <img src={logo9} alt=""/>
     </div>
     <div className='logo-slide'>
     <img src={Cimg} alt=""/>
     <img id='c-img' src={Cmmi} alt=""/>
     <img src={elets} alt=""/>
     <img src={logo6} alt=""/>
     <img src={logo7} alt=""/>
     <img src={logo8} alt=""/>
     <img src={logo9} alt=""/>
     </div>
     
    
 
   </div>
  )
}

export default Certification