import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Certification from './components/Certification';
import Cards from './components/Cards';
import SmallCard from './components/SmallCard';
import Conducting from './components/Conducting';
import Demo from './components/Demo'
import Request from './components/Request';
import Footer from './components/Footer';
import Product from './components/Product';



function App() {
 

  
  return (
    <div className="App"> 
    
      <Navigation/>
      <Header/>
      <Certification/>
      <Cards />
      <SmallCard  />
      <Conducting/>
      <Demo/>
      <Product/>
      <Request/>
      <Footer/>
     
    </div>
  );
}

export default App;
