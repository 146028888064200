import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import {FaCheckCircle } from "react-icons/fa";
import Online from "../Image/Exam-img/logo.gif"
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';


const Conducting = () => {
  useEffect(()=>{
    Aos.init({ offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 50,
      
    });

  },[]);
  
  return (
    <section>
      <Container data-aos="fade-right">
        <Row className='justify-content-center align-items-center flex-md-row flex-column-reverse'> 
            <Col md="6">
                <h3 className='pt-5 fs-2'>Conducting Exams  Became Easier  with Aakalan
                  Online Exam Software</h3>
                <Row className='py-3'>
                <Col md="6">
                <div style={{marginLeft:"1rem"}} className='py-1 d-flex   gap-2'><FaCheckCircle color='#289E8A' className='fs-4'/><p >Trusted by clients 15+ countries to seamlessly</p></div>
                <div style={{marginLeft:"1rem"}} className='py-1 d-flex   gap-2'><FaCheckCircle color='#289E8A' className='fs-3'/><p >Transformed the online test-taking methodsTop notch projects</p></div>
                <div style={{marginLeft:"1rem"}} className='py-1 d-flex   gap-2'><FaCheckCircle color='#289E8A' className='fs-3'/><p >Experienced in managing large scaled proctored Exams.</p></div>
                </Col>
                <Col md="6">
                <div style={{marginLeft:"1rem"}} className='py-1 d-flex   gap-2'><FaCheckCircle color='#289E8A' className='fs-3'/><p >Supported concurrency of 100,000+ sessionsTop notch projects</p></div>
                <div style={{marginLeft:"1rem"}} className='py-1 d-flex   gap-2'><FaCheckCircle color='#289E8A' className='fs-3'/><p >Seamlessly integrated with your ERP, CPR, LMS & Moodle</p></div>
                </Col>
                </Row>
            

            </Col>
            <Col data-aos="fade-left" md="6" className='d-flex justify-content-center justify-content-center '>
                <img className='w-80' src={Online} alt='#' />

           </Col>
        </Row>
      </Container>

    </section>
  )
}

export default Conducting