import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import himg from '../Image/header-img.webp';
import {FaCheckCircle } from "react-icons/fa";
import { Container } from 'react-bootstrap';
import "./Header.css"


const Header = () => {
  return (
 
   <section>
  <Container fluid>
    <Row className=' flex-md-row flex-column-reverse   '>
    <Col md="6" className='mt-0 mt-lg-5  pt-md-5 '>

           <div className='mb-0 mb-lg-4 magin-top '>
           <h1 className='font-h1 animate__animated animate__flipInX animate__delay-1s'>Your Trusted</h1>
           <h6 className='font-h6 animate__animated animate__zoomIn animate__delay-2s' >Partner in Skill</h6>
           </div>
           <h6 className='font-h7 animate__animated animate__flipInX animate__delay-1s' >Assessment</h6>
           <div className='row mt-0 mt-lg-5  '>
           <div className='col-md-6 flex' >
           <div className='py-2 d-flex textmargin gap-2 '><FaCheckCircle color='#289E8A'/><h5 className='animate__animated animate__slideInDown font-text '>Result Analysis Report</h5></div>
           <div  className='py-2 d-flex textmargin gap-2'><FaCheckCircle color='#289E8A'/><h5 className='animate__animated animate__slideInDown font-text'>Detailed Assessor Report</h5></div>
           </div>
           <div  className='col-md-6 flex'>
           <div className='py-2 d-flex gap-2 '><FaCheckCircle color='#289E8A'/><h5 className='animate__animated animate__slideInDown  font-text'>Question Bank Report</h5></div>
           <div className='py-2 d-flex gap-2 '><FaCheckCircle color='#289E8A'/><h5 className='animate__animated animate__slideInDown  font-text'>Student Feedback Report</h5></div>
          
           </div>
           </div>
          <div  className='mt-0 lg-mt-3 btn-div' >
            <Button className='py-3 mt-0 mt-lg-4  animate__animated animate__bounce animate__slideInUp'style={{backgroundColor:"#289E8A", color:"#fff",border:'none'}}   size="md" onClick={() => window.open('https://app.aakalan.org/')}>
           Explore our Portal
      </Button>
    
           </div>
        
    </Col>
    <Col md="6" className='p-0'>
        <img style={{ height:"100%" , width:"100%"}} src={himg} alt=""/>
    </Col>
    </Row>
 </Container>
   </section>
  )
}

export default Header