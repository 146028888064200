
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import "./SmallCard.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';
import FeaturesData from './FeaturesData';


function SmallCard() {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 50,

    });

  }, []);

  return (
    <section className='py-5 gap-2' style={{ backgroundColor: '#F8F8F8' }} id='features'>
      <Container>
        <Row>
          <Col md="12" className='text-center pb-4'>
            <h3 className='fs-2 py-1'>Mpowering Excellence Aakalan's Distinctive Features</h3>
            <h6 className='fs-5 py-1'>Unlocking the Potential of Skill Assessment</h6>
            <p className='fs-6 py-2'>Experience the power of Aakalan, the Skill Assessment Platform that redefines excellence with its extraordinary features. Our platform is tailored to assess students under Sector Skill Councils, seamlessly operating on web, mobile, and as a dedicated application. Aakalan's brilliance shines through its user-friendly interface, automated offline assessments, and unwavering commitment to NSQF guidelines and QP criteria.</p>

          </Col>
        </Row>
        {/* <Row className='gap-4 gap-md-0'>
    <Col  data-aos="fade-up" xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Precision} />
      <Card.Body className='py-3'>
        <Card.Title>Precision Assessment Creation</Card.Title>
        <Card.Text className='py-2'>
        Aakalan automates assessment creation aligned with standards, saving time and ensuring consistency by generating multiple question papers from a question bank
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>
      </Card.Body>
    </Card>
    </Col>
    <Col  data-aos="fade-up" xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Anti} />
      <Card.Body className='py-4'>
        <Card.Title>Vigilant Anti-Cheat Measures</Card.Title>
        <Card.Text className='py-2'>
        Our platform incorporates real-time proctoring to safeguard the integrity of assessments. Live video feeds and random photo capturing at regular intervals ensure a secure testing environment
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    <Col  data-aos="fade-up" xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Insightful} />
      <Card.Body className='py-4'>
        <Card.Title>Insightful Performance Analytics</Card.Title>
        <Card.Text className='py-2'>
        	Easily decipher performance data through graphical views. Filter results by Assessor, Training Partner, Job Role, State, and Date, gaining comprehensive insights at a glance.
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    <Col  data-aos="fade-up" xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Impeccable} />
      <Card.Body className='py-4'>
        <Card.Title>Precise Assessment Tracking</Card.Title>
        <Card.Text className='py-2'>
        Aakalan meticulously tracks assessments through Geo Location and Timestamps. Whether online or offline, every Assessor login and logout is recorded and communicated to SSC/AB.
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    </Row> */}
        {/* <Row  data-aos="fade-up" className='mt-lg-0 mt-xl-5'>
    <Col xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 m card-h mt-4 lg-mt-0'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Seamless} />
      <Card.Body className='py-4'>
        <Card.Title>Seamless Assessment Reminders</Card.Title>
        <Card.Text className='py-2'>
        Ensure a smooth assessment process with automated reminder emails sent to AB, Assessor, and SSC 24 hours prior to the assessment
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    <Col data-aos="fade-up"xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h mt-4 lg-mt-0'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Intelligent} />
      <Card.Body className='py-4'>
        <Card.Title>Intelligent Question Phasing</Card.Title>
        <Card.Text className='py-2'>
        Aakalan categorizes questions effortlessly as easy or tough, analyzing wrong and right attempts within a specific timeframe. A downloadable usage report helps you monitor question effectiveness
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    <Col  data-aos="fade-up" xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h mt-4 lg-mt-0'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Streamlined} />
      <Card.Body className='py-4'>
        <Card.Title>Streamlined Question Bank Upload</Card.Title>
        <Card.Text className='py-2'>
      	Simplify question bank management by uploading it in a user-friendly Excel format, including NOS and PC-wise mapping, difficulty levels, and marks.
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    <Col  data-aos="fade-up" lg="6" xl="3" className='mt-5 mt-lg-5 mt-xl-0'>
    <Card className='py-4 card-h mt-4 lg-mt-0'  style={{boxShadow:'0 30px 80px rgba(90, 144, 225, 0.1)',borderRadius:'15px'}}>
    <Card.Img style={{width:'4.5rem' ,marginLeft:"2rem"}} variant="top" src={Unparalleled} />
      <Card.Body className='py-4'>
        <Card.Title>Unparalleled Online/Offline Capability</Card.Title>
        <Card.Text className='py-2'>
        Aakalan's efficiency extends to remote areas with seamless transitions between online and offline modes while retaining all features
        </Card.Text>
        <div  className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{fontSize:"1.3rem"}}/> </div>


      
      </Card.Body>
    </Card>
   
    </Col>
    
    </Row> */}
        <Row data-aos="fade-up" className='mt-lg-0 mt-xl-5' >
          {
            FeaturesData.map((item)=>{
              return (
                <Col data-aos="fade-up" xl="3" lg="6" className='mt-5 mt-lg-5 mt-xl-0 py-1 py-lg-4' >
                  <Card className=' card-h' style={{ boxShadow: '0 30px 80px rgba(90, 144, 225, 0.1)', borderRadius: '15px' }}>
                    <Card.Img style={{ width: '4.5rem', marginLeft: "2rem" }} variant="top" src={item.src} />
                    <Card.Body className='py-3'>
                      <Card.Title>{item.title}</Card.Title>
                      <Card.Text className='py-2'>
                       {item.text}
                      </Card.Text>
                      <div className='fs-6 fw-bold' variant="link">Learn more<AiOutlineArrowRight className='link' style={{ fontSize: "1.3rem" }} /> </div>
                    </Card.Body>
                  </Card>
                </Col>

              )

            })
          }



        </Row>
      </Container>
    </section>
  );
}

export default SmallCard;