import React from 'react';
import "./Footer.css";
import Logo from "../Image/logo.png"
import { Link } from 'react-scroll';

const Footer = () => {
  return (
   <section>
    <footer className="footer-section">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="widget company-intro-widget">
              <Link href="index.html" className="site-logo"><img src={Logo} alt="logo"/></Link>


              <p>Empowering Individuals and Industries through Accurate Assessments, Strategic Consultancy, and Innovative Solutions. Unlocking Potential, Driving Growth. Connect with us to embark on a journey of success.</p>
              <ul className="company-footer-contact-list">
                <li><i className="fas fa-phone"></i> <a style={{color:"#ffff"}} href="tel:PHONE_NUM" > 0120- 4279926/4259822</a></li>
                <li><i className="fas fa-clock"></i>Mon - Sat 8.00 - 10.00</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mt-4 mt-lg-0">
            <div className="widget course-links-widget">
              <h5 className="widget-title">PRODUCTS</h5>
              <ul className="courses-link-list">
                <li><Link href="https://app.aakalan.org/"><i className="fas fa-long-arrow-alt-right"></i>Online Examination Solution</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Talent Assessment Solutions</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Coding Assessment Solutions</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Remote Proctoring Tools</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Hiring Solutions</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>360-degree Feedback Toolt</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mt-4 mt-lg-0">
            <div className="widget course-links-widget">
              <h5 className="widget-title">OTHERS</h5>
              <ul className="courses-link-list">
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Blog</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Accessibility</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Help & Support</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Webinars</Link></li>
                <li><Link href="#"><i className="fas fa-long-arrow-alt-right"></i>Glossary</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 mt-4 mt-lg-0">
            <div className="widget newsletter-widget">
              <h5 className="widget-title">Group Company</h5>
              <div className="footer-newsletter">
                <p>"Vikram Aviation Pvt Ltd"
'Trusted Partner for Precision Calibration, Expert Repairs, Composite Maintenance, and Consumable Solutions.'
Our group company fosters collaborative excellence across industries, uniting innovation and expertise.</p>
                <form className="news-letter-form">
                  <input  style={{backgroundColor:"#fff"}} type="email" name="news-email" id="news-email" placeholder="Your email address"/>
                  <input type="submit" value="Send"/>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
            <div className="col-md-6 col-sm-6 text-sm-left text-center">
              <span className="copy-right-text" style={{cursor:"pointer"}}> <Link to="home" spy={true} smooth={true} offset={-100} duration={500}>© 2023 Aakalan.org All Rights Reserved</Link> | Powered by </span> <span><a style={{color:"#ffff"}} href="https://www.demorgia.com"target='blank' > Demorgia</a> </span>

            </div>
            <div className="col-md-6 col-sm-6">
              <ul class="terms-privacy d-flex justify-content-sm-end justify-content-center">
                <li><Link href="#">Terms & Conditions</Link></li>
                <li><Link href="#">Privacy Policy</Link></li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </footer>

   </section>
  )
}

export default Footer